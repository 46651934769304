import axios from 'axios';
const BASE_API = process.env.VUE_APP_BASE_API


const http = axios.create({
  baseURL: BASE_API
})

export function sendMail(data) {
  return http.post('contacto', data);
}

export function sendNews(data) {
  return http.post('news', data);
}